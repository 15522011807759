import { Module, Action, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getFaqList } from '@/admin/api/faq';
import filterModel from './filter';
import FaqFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { FilterInputSuggestionsParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { getUsersList } from '@/admin/api/users';
import { processListForSelect } from '@/lib/utils/form';
import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';

export const MODULE_NAME = 'faq';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ContentFaq extends PageBaseModule {
    filter: Filter;

    constructor(module: ContentFaq) {
        super(module);

        const page = new PageEntity();

        page.setTitle('FAQ');
        page.setDefaultSortField('name');
        page.setSortName('FaqSort');

        const filter = new filterModel();
        this.filter = getModule(FaqFilter);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setBtnClassName('row-2');
        this.filter.setFilterName('FaqFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        const sort = await this.getSortForRequest();

        this.SET_IS_LOADING(true);

        const table = await getFaqList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        this.SET_TABLE(await this.prepareTable(table));
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles as TableTitle[])
            .filter((title) => title.visible === false && title.id !== 'id')
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'createdByUser' && cell) {
                    cell = `<a href="${window.location.origin}/users/user/${cell.id}" target="_blank" class="cp-table__item-link">
                                <span class="cp-table-column__accent">${cell.fullName}</span>
                            </a>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    updateSuggestionsSelectedFilter(selectedSuggestion: FilterInputSuggestionsParamsInterface) {
        this.filter.updateSuggestionsSelected(selectedSuggestion);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSearchFilter(params: { key: string; value: string }) {
        const filter = params.value ? `&filters[0][id]=fullName&filters[0][value]=${params.value}` : '';
        this.filter.updateSuggestionsValue(params);

        const table = await getUsersList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list, 'fullName') });
    }

    @Action({ rawError: true })
    reset() {
        this.RESET_TABLE();
    }
}
export default getModule(ContentFaq);
