import { Module } from 'vuex-module-decorators';
import store from '@/admin/store';
import FilterModule from '@/admin/store/filter';

@Module({ dynamic: true, store, name: 'FaqFilter', namespaced: true })
export default class FaqFilter extends FilterModule {
    constructor(module: FaqFilter) {
        super(module);
    }
}
