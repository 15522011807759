/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class FaqFilter {
    filterHandlers = {
        update: 'FaqFilter/updateFilter',
        reset: 'FaqFilter/resetFilter',
        resetState: 'FaqFilter/resetFilterState',
        removeSelected: 'FaqFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSuggestionsEntity('user');
        filter.setPlaceholder('user', 'Начните вводить имя пользователя');
        filter.setName('user', 'suggestions_user');
        filter.setPreviewName('user', 'Пользователь');
        filter.setAction('user', 'faq/updateSuggestionsSelectedFilter');
        filter.setSearchAction('user', 'faq/updateSuggestionsSearchFilter');
        filter.setIconClassName('user', 'user-search');

        this.filterModel = filter.filterModel;
    }
}
